import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import { mixins } from "@/plugins/mixins";
import { ElLoading } from "element-plus";
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

export default {
  name: "deviceVehicle",
  mixins: [mixins],
  components: {},
  watch: {
    'currentPage': function () {
      this.submitSearchForm();
    },
    'pageSize': function () {
      this.submitSearchForm();
    }
  },
  data() {
    return {
      isAdd: false,
      map: null,
      marker: null,
      parkType: "",
      address: null,
      type: 'ADD',
      showUpload: false,
      inputFile: '',
      switchLoading: false,
      currentId: null,
      detailDrawer: false,
      dataTotal: 0,
      currentPage: 1,
      pageSize: 10,
      searchFormModel: {
        bikeId: '',
        phone: '',
        time: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate() - 1).format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')]
      },
      row: {},
      rideRecord: {},
      controller: {},
      rideRecordList: [],
      pathParam: [],
      //线路
      columns: [{
        label: '车辆编号',
        prop: 'vehicleCode'
      }, {
        label: '车架编号',
        prop: 'frameCode'
      }, {
        label: '电池编号',
        prop: 'batteryCode'
      }, {
        label: '中控编号',
        prop: 'controllerCode'
      }, {
        label: '仓库名称',
        prop: 'warehouseName'
      }, {
        label: '状态',
        prop: 'statusDesc'
      }, {
        label: '在库状态',
        prop: 'wareStatus'
      }, {
        label: '最后定位时间',
        prop: 'lastGpsTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'deviceVehicle_add',
        type: 'primary',
        click: this.add,
        permissions: ['deviceVehicle_add']
      }, {
        name: '导入',
        code: 'deviceVehicle_upload',
        type: 'primary',
        click: this.upload,
        permissions: ['deviceVehicle_upload']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'deviceVehicle_edit',
        click: this.edit,
        permissions: ['deviceVehicle_edit']
      }, {
        name: '详情',
        code: 'deviceVehicle_detail',
        click: this.detail,
        permissions: ['deviceVehicle_detail']
      }],
      searchItems: [{
        prop: 'frameId',
        label: '车架编号',
        type: 'select',
        items: [],
        itemProp: {
          label: 'frameCode',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[0].loading = true;
            this.$api.business.deviceFrame.getList({
              page: 1,
              size: 20,
              frameCode: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[0].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[0].loading = false;
            });
          }
        }
      }, {
        prop: 'batteryId',
        label: '电池编号',
        type: 'select',
        items: [],
        itemProp: {
          label: 'batteryCode',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[1].loading = true;
            this.$api.business.deviceBattery.getList({
              page: 1,
              size: 20,
              batteryCode: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[1].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[1].loading = false;
            });
          }
        }
      }, {
        prop: 'controllerId',
        label: '中控序列号',
        type: 'select',
        items: [],
        itemProp: {
          label: 'serialNumber',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[2].loading = true;
            this.$api.business.deviceController.getList({
              page: 1,
              size: 20,
              serialNumber: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[2].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[2].loading = false;
            });
          }
        }
      }, {
        prop: 'pkWarehouse',
        label: '仓库',
        type: 'select',
        items: [],
        itemProp: {
          label: 'name',
          value: 'id'
        },
        remote: true,
        loading: false,
        remoteMethod: name => {
          if (name.length >= 1) {
            this.searchItems[3].loading = true;
            this.$api.business.carWarehouse.getList({
              page: 1,
              size: 20,
              name: name
            }).then(res => {
              if (res.code === 200) {
                this.searchItems[3].items = res.data.records;
              }
            }).finally(() => {
              this.searchItems[3].loading = false;
            });
          }
        }
      }, {
        prop: 'vehicleCode',
        label: '车辆编号'
      }]
    };
  },
  methods: {
    //骑行记录
    submitSearchForm() {
      let param = {
        bikeId: this.searchFormModel.bikeId,
        phone: this.searchFormModel.phone,
        beginTime: this.searchFormModel.time[0],
        endTime: this.searchFormModel.time[1],
        page: this.currentPage,
        size: this.pageSize
      };
      this.$api.business.rideRecord.getList(param).then(res => {
        if (res.code === 200) {
          this.rideRecordList = res.data.records;
          this.dataTotal = res.data.total;
        } else {
          ElMessage.error(res.message);
        }
      });
    },
    //最近订单
    getRecord() {
      let param = {
        bikeId: this.searchFormModel.bikeId,
        beginTime: this.searchFormModel.time[0],
        endTime: this.searchFormModel.time[1],
        page: 1,
        size: 1
      };
      this.$api.business.rideRecord.getList(param).then(res => {
        if (res.code === 200) {
          this.rideRecord = res.data.records[0];
        } else {
          ElMessage.error(res.message);
        }
      });
    },
    //查询状态
    getController() {
      this.$api.business.deviceController.getController({
        id: this.row.controllerId
      }).then(res => {
        if (res.code === 200) {
          this.controller = res.data;
        } else {
          ElMessage.error(res.message);
        }
      });
    },
    //查询轨迹
    getGPS(row) {
      this.$api.business.rideRecord.getGPS({
        recordId: row.id,
        beginTime: row.beginTime
      }).then(res => {
        if (res.code === 200) {
          this.pathParam = [];
          res.data.forEach(v => {
            if (v.longitude > 0) this.pathParam.push([v.longitude, v.latitude]);
          });
          this.$refs.map.gps(this.pathParam);
        }
      }).finally(() => {});
    },
    //修改车辆状态
    updateState(val) {
      this.$api.business.deviceController.updateState({
        state: val,
        bikeId: this.row.id
      }).then(res => {
        if (res.code === 200) {
          ElMessage.success("操作成功");
          this.getController();
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {});
    },
    detail(row) {
      this.parkType = '';
      this.address = {
        lng: row.longitude,
        lat: row.latitude,
        add: row.parkAdd,
        areaId: row.areaId
      };
      this.row = row;
      this.searchFormModel.bikeId = row.id;
      this.submitSearchForm();
      this.getRecord();
      this.getController();
      this.isAdd = true;
    },
    downTemplate() {
      const loading = ElLoading.service();
      this.$api.business.deviceVehicle.exportTemplate().then(data => {
        this.blobFile(data, '车辆导入模板文件.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {
          this.showUpload = false;
        });
      }).finally(() => {
        loading.close();
      });
    },
    fileChange(ev) {
      let selectFile = ev.target.files[0];
      if (selectFile) {
        let name = selectFile.name;
        let type = name.substring(name.lastIndexOf('.') + 1);
        if (type !== 'xls' && type !== 'xlsx') {
          this.$message.error('只能上传excel文件');
          this.inputFile = '';
        } else {
          this.inputFile = selectFile;
        }
      }
    },
    doImp() {
      if (!this.inputFile) {
        ElMessage.error('请选择文件');
        return;
      }
      const loading = ElLoading.service();
      // 构造一个 FormData，把后台需要发送的参数添加到FormData里面，这样就可以直接把formData作为参数传递了
      let formData = new FormData();
      formData.append('file', this.inputFile); //接口需要传递的参数
      this.$api.business.deviceVehicle.importFile(formData).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.inputFile = '';
          this.showUpload = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    add() {
      this.$router.push('/deviceVehicleAdd');
    },
    upload() {
      this.showUpload = true;
    },
    edit(row) {
      this.$router.push({
        path: '/deviceVehicleAdd',
        query: {
          id: row.id
        }
      });
    }
  }
};